import * as React from 'react';
import { useEffect } from 'react';
import { useGetList } from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Grid,
  Link,
} from '@mui/material';

export const LandingPage = () => {
  const { data, isLoading } = useGetList('appSettings', {
    pagination: { page: 1, perPage: 200 },
  });

  useEffect(() => {
    if (data && document.getElementById('applicationVersion')) {
      document.getElementById('applicationVersion').innerText =
        'Version ' + data[0]['version'];
    }
  }, [data]);

  if (isLoading) {
    return <div></div>;
  }
  return (
    <Box sx={{ marginTop: '64px', marginX: '64px' }}>
      <Grid container spacing={8}>
        {data.map((e) => {
          return e.counterparts
            .filter((c) => c.type === 'ACTIVE')
            .map((cp) => (
              <Grid item={true} xs={3} key={cp.id}>
                <Link
                  underline="none"
                  sx={{ width: '100%' }}
                  href={
                    '/main?realm=' +
                    cp.realm +
                    '&clientId=' +
                    cp.clientId +
                    '#/submissions'
                  }
                >
                  <Card>
                    <CardHeader title={cp.name} />
                    <CardMedia
                      component="img"
                      height={194}
                      image={cp.secret}
                      alt={cp.name}
                      sx={{
                        padding: '1em 1em 0 1em',
                        width: '80%',
                        objectFit: 'contain',
                        margin: 'auto',
                      }}
                    ></CardMedia>
                    <CardActions sx={{ margin: 'auto' }}>
                      <Button role={'button'} fullWidth variant="contained">
                        Login
                      </Button>
                    </CardActions>
                  </Card>
                </Link>
              </Grid>
            ));
        })}
      </Grid>
    </Box>
  );
};
